/* eslint-disable no-plusplus */
// @flow
/* eslint-disable import/prefer-default-export */
import React from 'react';
import { scroller } from 'react-scroll';
import formatDate from 'date-format';
import { titlePathnameMap } from '../constants';

export const pagerize = ({
  entries,
  page,
  perPage,
}: {
  entries: ?Array<any>,
  page: number,
  perPage: number,
}): Array<any> => {
  if (!entries) {
    return [];
  }

  return entries.slice(page * perPage, page * perPage + perPage);
};

export const getDateString = (d: any): string => {
  return d.toLocaleDateString('ja-JP', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
};

// export function isIPad() {
//   if (typeof window === 'undefined' || !window.navigator) {
//     return false;
//   }

//   const ua = window.navigator.userAgent;

//   if (ua.indexOf('iPad') > -1) {
//     return true;
//   }

//   if (ua.indexOf('Macintosh') > -1) {
//     try {
//       document.createEvent('TouchEvent');

//       return true;
//     } catch (e) {
//       //
//     }
//   }

//   return false;
// }

export const gtag = (arg) => {
  if (window.dataLayer) {
    console.debug('track event', arg);
    window.dataLayer.push(arg);
  }
};

export const trackAppDownload = (storeName: string, clickArea: string) => {
  gtag({
    event: 'app_download',
    ga_click_area: clickArea,
    ga_platform: storeName,
  });
};

export const trackLogin = () => {
  gtag({
    event: 'login',
  });
};

// Use  into view
// Some browser don't scroll to the target if the location hash already setted.
export const scrollToID = (id: string, offset?: number) => {
  const targetId = id.replace('#', '');
  const target = document.getElementById(targetId);

  if (target && target.scrollIntoView) {
    target.scrollIntoView(true);
    window.scrollBy(0, offset);
  }
};

export const smoothScrollToElement = (element: string, options: Object) => {
  setTimeout(() => {
    const target = element.replace('#', '');

    scroller.scrollTo(target, options);
  }, 500);
};

export const newQS = (
  input: Object,
  old: ?Object = {},
  options: ?Object = {}
) => {
  // $FlowFixMe
  return options.type.indexOf('In') >= 0 ? { ...old, ...input } : { ...input };
};

/**
 Break a contentful's long news headline with separator "|"
 */
export const formatNewsHeadline = (headline: string) => {
  const splitted = headline.split('|');

  if (splitted.length === 1) {
    return headline;
  }

  return (
    <>
      {splitted.map((line, i) => {
        if (i === splitted.length - 1) {
          return <span key={i}>{line}</span>;
        }

        return (
          <span key={i}>
            {line}
            <br />
          </span>
        );
      })}
    </>
  );
};

export const getOgData = (options = {}) => {
  const { img, title, description, siteMetadata = {} } = options;
  let imgUrl;
  let twitterImgUrl;

  if (img) {
    imgUrl = img.replace(/^\/\//, 'https://');
    twitterImgUrl = img.replace(/^\/\//, 'https://');
  } else if (siteMetadata.siteUrl) {
    imgUrl = `${siteMetadata.siteUrl}${siteMetadata.ogImage}`;
    twitterImgUrl = `${siteMetadata.siteUrl}${siteMetadata.twitterImage}`;
  }

  return [
    {
      property: 'og:image',
      content: imgUrl,
    },
    {
      property: 'twitter:image',
      content: twitterImgUrl,
    },
    {
      property: `og:title`,
      content: title || siteMetadata.title,
    },
    {
      name: `twitter:title`,
      content: title || siteMetadata.title,
    },
    {
      property: `og:description`,
      content: description || siteMetadata.description,
    },
    {
      name: `twitter:description`,
      content: description || siteMetadata.description,
    },
    {
      name: `twitter:card`,
      content: `summary_large_image`,
    },
  ];
};

export const getPathLevels = (path) => {
  if (!path || typeof path !== 'string') {
    return [];
  }

  const levels = path.split('/').filter((level) => level);
  const result = [
    {
      path: '/',
      title: titlePathnameMap['/'],
    },
  ];

  for (let i = 0; i < levels.length; i++) {
    const levelPath = `${result[i].path}${levels[i]}/`;

    if (titlePathnameMap[path]) {
      result.push({
        path: levelPath,
        title: titlePathnameMap[path],
      });
    }
  }

  return result;
};

const WEEK_DAY_MAPPER = {
  '0': '日',
  '1': '月',
  '2': '火',
  '3': '水',
  '4': '木',
  '5': '金',
  '6': '土',
};

export const getJPDateString = (dateString = '') => {
  const dateObj = new Date(dateString);

  return formatDate(
    `yyyy年MM月dd日（${WEEK_DAY_MAPPER[dateObj.getDay()]}）hh:mm`,
    dateObj
  );
};

export function createArray(from, to) {
  const result = [];

  // eslint-disable-next-line no-plusplus
  for (let i = from; i <= to; i++) {
    result.push(i);
  }

  return result;
}
